<template>
  <slot></slot>
  <BottomNav />
</template>

<script setup lang="ts">
useNewkiSeo()
</script>

<style scoped lang="scss"></style>
